import * as React from 'react'
import { useLayoutEffect, useRef } from 'react'
import { Column } from '../typings'

const Component = ({ focus, onChange, value, selectData }) => {
    const ref = useRef<HTMLSelectElement>(null)

    useLayoutEffect(() => {
        if (focus) {
            (typeof ref.current?.onselect === 'function') && ref.current?.onselect(value)
        } else {
            ref.current?.blur()
        }
    }, [focus])

    return (
        <select
            className='dsg-input'
            ref={ref}
            onChange={(e) => onChange(e.target.value || null)}
            defaultValue={value || ''}
        >
            {selectData.map((item, index) => {
                return item === value ? <option key={index} selected>{item}</option> : <option key={index}>{item}</option>
            })}
        </select>
    )
}

export function selectColumn<TRow = any>({
    key,
    selectData,
    ...rest
}: Partial<Column<TRow>> & { key: string }): Partial<Column<TRow>> {
    return {
        render: ({ focus, rowData, setRowData }) => (
            <Component
                value={rowData[key]}
                focus={focus}
                onChange={(value: any) => setRowData({ ...rowData, [key]: value || null })}
                selectData={selectData}
            />
        ),
        deleteValue: ({ rowData }) => ({ ...rowData, [key]: null }),
        copyValue: ({ rowData }) => rowData[key],
        pasteValue: ({ rowData, value }) => ({ ...rowData, [key]: value || null }),
        ...rest,
    }
}